import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import MenuDesktop from "./layout-menu-desktop.component";
import MenuMobile from "./layout-menu-mobile.component";

interface ILayoutMenuProps extends RouteComponentProps<any> {
  isMobile: boolean;
  isOpen: boolean;
  menuEl: any;
  history: any;
  handleCloseMenu: any;
}

const LayoutMenu: React.FC<ILayoutMenuProps> = ({
  isMobile,
  isOpen,
  menuEl,
  history,
  handleCloseMenu
}) => {
  const pathname = history.location.pathname;

  function doChangePage(url: string) {
    handleCloseMenu();

    try {
      history.push(url);
    } catch (error) {
      console.log(error);
    }
  }

  if (!isMobile) {
    return (
      <MenuDesktop
        isOpen={isOpen}
        pathname={pathname}
        doChangePage={doChangePage}
      />
    );
  }

  return (
    <MenuMobile
      menuEl={menuEl}
      doChangePage={doChangePage}
      handleClose={handleCloseMenu}
    />
  );
};

export default withRouter(LayoutMenu);

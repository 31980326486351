import React from "react";

import {
  SmsFailedIcon,
  MailIcon,
  DashboardIcon
} from "../components/ui-libraries/icons";

export enum ROUTES {
  Home = "/",
  Dashboard = "/dashboard",
  DashboardCase = "/dashboard/:caseId",
  DashboardKYC = "/dashboard/:caseId/kyc-form",
  Profile = "/profile",
  Messages = "/messages",
  Notifications = "/notifications",
  Feedback = "/feedback",
  Login = "/login",
  ForgotPassword = "/forgot-password",
  ChangePassword = "/password-reset",
  ProjectMembers = "/project-members"
}

export const SIDE_BAR = [
  {
    keyi18n: "dashboard",
    linkTo: ROUTES.Dashboard,
    icon: <DashboardIcon />
  },
  {
    keyi18n: "affiliate",
    linkTo: ROUTES.DashboardCase,
    isHide: true
  },
  {
    keyi18n: "kyc-form",
    linkTo: ROUTES.DashboardKYC,
    isHide: true
  },
  {
    keyi18n: "messages",
    linkTo: ROUTES.Messages,
    icon: <MailIcon />
  },
  {
    keyi18n: "feedback",
    linkTo: ROUTES.Feedback,
    icon: <SmsFailedIcon />
  },
  {
    keyi18n: "profile",
    linkTo: ROUTES.Profile,
    isHide: true
  },
  {
    keyi18n: "messages",
    linkTo: ROUTES.Messages,
    isHide: true
  }
];

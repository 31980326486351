import { lazy } from "react";
import { RouteProps } from "react-router-dom";

import { ROUTES } from "constants/navigation";

const routeDatas: RouteProps[] = [
  {
    exact: true,
    path: ROUTES.Login,
    component: lazy(() => import("pages/public/login/login.container"))
  },
  {
    exact: true,
    path: ROUTES.ForgotPassword,
    component: lazy(() =>
      import("pages/public/forgot_password/forgot-password.container")
    )
  },
  {
    exact: true,
    path: ROUTES.ChangePassword,
    component: lazy(() =>
      import("pages/public/change_password/change-password.container")
    )
  }
];

export { routeDatas };
 
import React, { useEffect } from "react";
import styles from "./chat-history.module.scss";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import {
  AutoSizer,
  List,
  Size,
  ListRowProps,
  CellMeasurer,
  CellMeasurerCache
} from "react-virtualized";

import { Typography } from "components/ui-libraries";

import { formatDateFromNow } from "utils/date.util";

interface iChatHistoryProps {
  datas: any[];
  rowCount: number;
  scrollToIndex?: number;
  rowHeight?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    senderText: {
      flex: "auto",
      padding: "8px 12px",
      borderRadius: 14,
      backgroundColor: theme.palette.background.default,
      minWidth: 40,
      float: "left"
    },
    receiverText: {
      flex: "auto",
      display: "inline-block",
      padding: "8px 12px",
      borderRadius: 14,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      backgroundColor: theme.palette.secondary.main,
      minWidth: 40,
      float: "right"
    }
  })
);

const _cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 50
});

const ChatHistory: React.FC<iChatHistoryProps> = ({
  datas,
  rowCount,
  scrollToIndex
}) => {
  const classOwns = useStyles();

  useEffect(() => {
    return function clearCache() {
      try {
        _cache.clearAll();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  function _rowRenderer({ index, key, parent, style }: ListRowProps) {
    const data = datas[index];
    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ measure }) => (
          <div style={style} className={styles.row__body}>
            {data.mine ? (
              <div className={styles.receiver}>
                <div
                  className={`${styles.user_info} ${styles["user_info--receiver"]}`}
                >
                  <Typography
                    align="right"
                    variant="caption"
                    color="textSecondary"
                    component="div"
                    className={styles.user_info_time}
                  >
                    {formatDateFromNow(data.dateTime)}
                  </Typography>
                </div>
                <div className={classOwns.receiverText}> {data.message}</div>
              </div>
            ) : (
              <div className={styles.sender}>
                <div className={styles.user_info}>
                  <Typography align="left" variant="subtitle2" component="div">
                    {data.user}
                  </Typography>
                  <Typography
                    align="right"
                    variant="caption"
                    color="textSecondary"
                    component="div"
                    className={styles.user_info_time}
                  >
                    {formatDateFromNow(data.dateTime)}
                  </Typography>
                </div>
                <div className={classOwns.senderText}>{data.message}</div>
              </div>
            )}
          </div>
        )}
      </CellMeasurer>
    );
  }

  return (
    <div className={styles.list}>
      <AutoSizer>
        {({ width, height }: Size) => {
          return (
            <List
              scrollToIndex={scrollToIndex}
              height={height}
              overscanRowCount={0}
              width={width}
              deferredMeasurementCache={_cache}
              rowCount={rowCount}
              rowHeight={_cache.rowHeight}
              rowRenderer={_rowRenderer}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default ChatHistory;

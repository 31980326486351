import React from "react";
import style from "./layout.module.scss";
import { withRouter } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";

import LayoutHeaderComponent from "./layout-header.component";
import MenuComponent from "./layout-menu/layout-menu.component";
import { ContainerDiv } from "components/ui-own/wrapper";

import useLayoutHook from "./layout.hook";
import { useThemesContext } from "context/theme.context";

import classnames from "classnames";

const Layout: React.FC = ({ history, children }: any) => {
  const { isMobile } = useThemesContext();
  const {
    isShow,
    menuEl,
    isAuth,
    isOpen,
    authData,
    onLogout,
    onToggle,
    handleCloseMenu,
  } = useLayoutHook(isMobile, history);

  return (
    <>
      <CssBaseline />
      {isAuth && (
        <MenuComponent
          menuEl={menuEl}
          isOpen={isOpen}
          isMobile={isMobile}
          handleCloseMenu={handleCloseMenu}
        />
      )}

      <LayoutHeaderComponent
        isMobile={isMobile}
        isAuth={isAuth}
        isOpen={isOpen}
        authData={authData}
        onLogout={onLogout}
        onToggle={onToggle}
      />
      <ContainerDiv
        padding={isAuth}
        className={classnames("scrollbar", {
          [style["body--close"]]: !isOpen && isAuth,
          [style["body--not-login"]]: !isAuth,
          [style["body--has-login"]]: isAuth,
          [style["body--mobile"]]: isMobile,
        })}
      >
        <div className={style.wrapper}>{isShow && children}</div>
      </ContainerDiv>
    </>
  );
};

export default withRouter(Layout);

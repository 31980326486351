export const API_ROOT =
  process && process.env.REACT_APP_ENV === "development"
    ? "https://vervedirect-dev-api.azurewebsites.net/api"
    : "https://vervedirect-prod-api.azurewebsites.net/api";

export const JWT_THE_KEY_OF_THE_LIFE = "dfo_v_d";

export interface BlockUI {
  isBlocking: boolean;
  message?: string;
}

export enum API_MODULES {
  Account = "accounts",
  Messages = "messages",
  Affiliate = "affiliates",
  Group = "groups",
  KYCForm = "kyc"
}

export enum KEYBOARD {
  Enter = 13
}

export enum HTTP_CODE {
  Unauthorized = 401
}

export enum SNACKBAR_TYPE {
  Error = "error"
}

export enum TIMEOUT {
  Default = 300,
  Search = 300,
  IntervalToken = 15 * 60 * 1000, // seconds
  RefreshToken = 15 // minutes
}

import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import {
  IconButton,
  Popover, 
  Divider,
  Tooltip
} from "components/ui-libraries";

import { PersonIcon } from "../../ui-libraries/icons";

import SettingsMain from "./user-settings-main.component";
import SettingsModules from "./user-settings-modules.component";
import SettingsThemDarkTheme from "./user-settings-theme-dark-theme.component";
import SettingsThemPallete from "./user-settings-theme-pallete.component";
import SettingsTheme from "./user-settings-theme.component";
import SettingsInfo from "./user-settings-info.component";
import { SETTINGS } from "./user-settings.constant";
import { ROUTES } from "constants/navigation";

import { useTranslation } from "react-i18next";

interface IUserSettingsProps extends RouteComponentProps {
  classes: any;
  authData: any;
  onLogout: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300
    }
  })
);

const UserSettings: React.FC<IUserSettingsProps> = ({
  authData,
  onLogout,
  history
}) => {
  const classOwns = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [tabTypeSub, setTabTypeSub] = useState<number>(-1);
  const { t } = useTranslation(["common"]);

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
    setTabTypeSub(SETTINGS.EMPTY);
  }

  function doLogout() {
    onLogout();
  }

  function goToSubSetting(type: number) {
    setTabTypeSub(type);
  }

  function doBack() {
    goToSubSetting(SETTINGS.EMPTY);
  }

  function doChangePassword() {
    handleClose();
    history.push(ROUTES.ChangePassword);
  }

  function getSubSetting(type: number) {
    switch (type) {
      case SETTINGS.DARK_THEME:
        return <SettingsThemDarkTheme doBack={doBack} />;
      case SETTINGS.THEMES:
        return <SettingsThemPallete doBack={doBack} />;
      default:
        return null;
    }
  }

  return (
    <>
      <Tooltip title={t("settings")}>
        <IconButton
          edge="end"
          aria-label="Settings"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <Popover
        transitionDuration={200}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        classes={{ paper: classOwns.root }}
      >
        {tabTypeSub === SETTINGS.EMPTY && (
          <>
            <SettingsInfo authData={authData} />
            <Divider />
            <SettingsModules 
              handleClose={handleClose} 
              history={history} 
              authData={authData}
            />
            <Divider />
            <SettingsTheme goToSubSetting={goToSubSetting} />
            <Divider />
            <SettingsMain
              doLogout={doLogout}
              doChangePassword={doChangePassword}
            />
          </>
        )}
        {tabTypeSub !== SETTINGS.EMPTY && getSubSetting(tabTypeSub)}
      </Popover>
    </>
  );
};

export default withRouter(UserSettings);

import React from "react";
import { Route } from "react-router-dom";

import { routeDatas } from "./public.components.route";

const PublicRoute: React.SFC = () => (
  <>
    {routeDatas.map((route, i) => (
      <Route key={i} {...route} />
    ))}
  </>
);

export default PublicRoute;

import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import PublicTypeRoute from "./public/public.type.route";
import PrivateTypeRoute from "./private/private.type.route";

import { ProgressLoader, LayoutApp } from "../components/ui-own";

import AuthProvider from "../context/auth.context";
import ThemeProvider from "../context/theme.context";
import NotificationProvider from "../context/notification.context";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Root: React.SFC = () => {
  return (
    <Router>
      <ThemeProvider>
        <NotificationProvider>
          <AuthProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <LayoutApp>
                <Suspense fallback={<ProgressLoader />}>
                  <PublicTypeRoute />
                  <PrivateTypeRoute />
                </Suspense>
              </LayoutApp>
            </MuiPickersUtilsProvider>
          </AuthProvider>
        </NotificationProvider>
      </ThemeProvider>
    </Router>
  );
};

export default Root;

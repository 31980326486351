export default {
  common: {
    kyc_status: "KYC Status",
    af_status: "AF Status",
    resend_kyc_link: "Resend KYC Link",
    download: "Download",
    exporting: "Exporting",
    start_date: "Start date",
    end_date: "End date",
    affiliate_manager: "Affiliate manager",
    sign_up_date: "Sign up date",
    filters: "Filters",
    name_of_applicant: "Name of Applicant",
    registered_address: "Registered Address",
    office_contact_number: "Office Contact Number",
    primary: "Primary",
    secondary: "Secondary",
    message: "Message",
    reason: "Reason",
    confirm: "Confirm",
    am_direct_approval_description:
      "Only approves in the Everflow and Salesforce",
    am_manual_approval_description: "Only approves in the Automation Portal",
    reject_description: "Reject this case",
    subject: "Subject",
    affiliate: "Affiliate",
    type_a_message: "Type a message",
    press_esc_to_close: "Press 'ESC' key to close",
    affiliates_approved_recently: "Affiliates Approved Recently",
    your_recent_assigned_cases: "Your recent/assigned cases",
    billing_info: "Billing info",
    declaration: "Declaration",
    approveLegal_description: " ",
    approveInfo_description: " ",
    see_all: "See all",
    process: "Process",
    place: "Place",
    approved: "Approved",
    proof_name: "Proof name",
    other_proof_of_address_document_name:
      "Other proof of address document name",
    last_update: "Last Update",
    ruun_media_llc: "Ruun Media LLC",
    latest_update: "Latest Update",
    case_notes: "Case Notes",
    marketing: "Marketing",
    view_kyc_form: "View KYC Form",
    affiliate_kyc_form: "Affiliate KYC form",
    primary_category: "Primary category",
    payment_model: "Payment model",
    payment_to: "Payment to",
    tax_class: "Tax class",
    tax_id: "Tax ID",
    show_more: "Show more",
    date_of_birth: "Date of birth",
    show_less: "Show less",
    residential_address: "Residential address",
    office_address: "Office address",
    proof_of_address_info: "Proof of address info",
    kyc_form: "KYC form",
    personal_poc_details: "Personal / POC Details",
    phone: "Phone",
    expiry: "Expiry",
    poc_complete_name: "POC complete name",
    contact_information: "Contact information",
    full_name: "Full name",
    im: "IM",
    currency: "Currency",
    birthdate: "Birthdate",
    contact_details: "Contact Details",
    place_of_incorporation: "Place of incorporation",
    date_of_commencement: "Date of commencement of business",
    date_of_incorporation: "Date of incorporation",
    social_media_handles: "Social Media Handles",
    company_info: "Company info",
    other_sm_handles: "Other SM Handles",
    affiliate_billing_contact: "Affiliate billing contact",
    social_media_handles_other: "Social Media Handles - Other",
    company_details: "Company details",
    company_name: "Company name",
    company_information: "Company information",
    marketing_info: "Marketing info",
    contact_name: "Contact name",
    nationality: "Nationality",
    sex: "Sex",
    civil_status: "Civil status",
    social_media_handles_others: "Social media handles others",
    skype: "Skype",
    position: "Position",
    info: "Info",
    other: "Other",
    comments: "Comments",
    referral: "Referral",
    am_referrer: "AM referrer",
    affiliate_referrer: "Affiliate referrer",
    affiliate_referrer_id: "Affiliate referrer ID",
    referral_notes: "Referral notes",
    reject: "Reject",
    rejected: "Rejected",
    other_info: "Other Info",
    am_direct_approval: "AM Direct Approval",
    am_manual_approval: "AM Manual Approval",
    info_approve: "Info Approve",
    legal_approve: "Legal Approve",
    contact_affiliate: "Contact Affiliate",
    direct_chat: "Direct Chat",
    assign_case: "Assign Case",
    upload_documents: "Upload Documents",
    proof_of_company_registration: "Proof of Company Registration",
    government_issued_id: "Government Issued ID",
    proof_of_address: "Proof of Address",
    other_proof_of_address: "Other proof of address?",
    other_proof_of_address_name: "Other proof of address name",
    other_proof_of_address_expiry: "Other proof of address expiry",
    about_me: "About me",
    assignee: "Assignee",
    folders: "Folders",
    inbox: "Inbox",
    drafts: "Drafts",
    trash: "Trash",
    important: "Important",
    promotions: "Promotions",
    no_results_found: "No results found",
    loading: "Loading",
    latest_cases: "Latest Cases",
    social: "Social",
    labels: "Labels",
    case_id: "Case Id",
    address: "Address",
    date: "Date",
    case_event_history: "Case Event History",
    contact: "Contact",
    everflow_profile: "Everflow Profile",
    salesForce_profile: "SalesForce Profile",
    job_title: "Job Title",
    work_phone: "Work Phone",
    cell_phone: "Cell Phone",
    website: "Website",
    im_service: "IM Service",
    legal_type: "Legal Type",
    link: "Link",
    status: "Status",
    type: "Type",
    url_caseId: "Affiliate",
    documents: "Documents",
    in_process: "In Process",
    all_cases: "All Cases",
    dashboard: "Dashboard",
    passwords_do_not_match: "Passwords do not match",
    new_password: "New password",
    confirm_new_password: "Confirm new password",
    send: "Send",
    email_must_be_a_valid_email: "Email must be a valid email",
    please_wait: "Please wait...",
    successful_password_reset_title: "Successful password reset!",
    successful_password_reset_message:
      "You can now use your new password to log in to your account!",
    back_to_login: "Back to login",
    enter_your_new_password_to_continue: "Enter your new password to continue",
    change_password: "Change Password",
    your_password_must_have: "Your password must have:",
    a_lowercase_letter:
      "At least one lower affiliate letter <i>(a through z)</i>",
    a_capital_letter:
      "At least one <b>UPPER</b> affiliate letter <i>(A through Z)</i>",
    a_number: "At least one number <i>(0 through 9)</i>",
    minimum_8_characters: "8 or more characters",
    a_special_character:
      "At least one special character. Special characters include: <b>!@#$%^&</b>",
    project_members: "Project members",
    content: "Content",
    title: "Title",
    notes: "Notes",
    sending: "Sending",
    location: "Location",
    messages: "Messages",
    settings: "Settings",
    mobile: "Mobile",
    group: "Group",
    profile: "Profile",
    notifications: "Notifications",
    feedback: "Feedback",
    app_name: "VerveDirect",
    lets_work: "Let's Work",
    lets_go: "Let's Go",
    sign_in: "SIGN IN",
    sign_out: "Sign out",
    username: "Username",
    password: "Password",
    delete: "Delete",
    pallete: "Pallete",
    this_field_is_required: "This field is required",
    fields: "Fields",
    users: "Users",
    dark_theme: "Dark Theme",
    pallete_tip_1:
      "A primary color is the color displayed most frequently across your app’s screens and components.",
    pallete_tip_2:
      "A secondary color provides more ways to accent and distinguish your product. Having a secondary color is optional, and should be applied sparingly to accent select parts of your UI.",
    dark_theme_tip_1:
      "Dark theme turns the light surfaces of the page dark, creating an experience ideal for night. Try it out!",
    dark_theme_tip_2:
      "Your Dark theme setting will apply to this browser only.",
    language: "Language",
    event: "Event",
    themes: "Themes",
    database_viewer: "Database Viewer",
    export: "Export",
    import: "Import",
    roles: "Roles",
    batches: "Batches",
    monitor: "Monitor",
    quality_control: "Quality Control",
    search: "Search",
    basic_search: "Basic Search",
    advanced_search: "Advanced Search",
    total: "Total",
    forgot_password: "forgot password?",
    forgot_password_tip:
      "Enter your email address & we'll send you a link to reset your password.",
    add: "Add",
    your_email: "Your Email",
    show_search_options: "Show search options",
    name: "Name",
    email: "Email",
    fullname: "Full Name",
    saving: "Saving",
    updating: "Updating",
    deleting: "Deleting",
    url: "Url",
    clear: "Clear",
    home: "Home",
    copy_signup_link: "Copy Signup link",
    copied_signup_link: "Copied",
    ef_affiliate_id: "EF Affiliate Id",
    everflow_status: "Everflow Status"
  },
  theme: {
    dark: "On",
    light: "Off",
  },
  color: {
    red: "Red",
    pink: "Pink",
    purple: "Purple",
    deepPurple: "Deep Purple",
    indigo: "Indigo",
    blue: "Blue",
    lightBlue: "Light Blue",
    cyan: "Cyan",
    teal: "Teal",
    green: "Green",
    lightGreen: "Light Green",
    lime: "Lime",
    yellow: "Yellow",
    amber: "Amber",
    orange: "Orange",
    deepOrange: "Deep Orange",
  },
};

import React, { useState } from "react";
import styles from "./table.module.scss";

import {
  Grid,
  InputBase,
  IconButton,
  Divider,
  Typography,
  Tooltip,
} from "components/ui-libraries";

import { FilterListIcon, SearchIcon } from "components/ui-libraries/icons";

import { useTranslation } from "react-i18next";

import { useBackgroundStyles } from "utils/theme.utils";

import { KEYBOARD } from "constants/common";

const SearchGroup: React.FC<any> = ({
  isMobile,
  rowCount,
  showAdvanceSearch,
  isShowAdvanced,
  onSearch,
}) => {
  const [keyword, setKeyword] = useState<string>("");
  const { t } = useTranslation(["common"]);
  const classOwns = useBackgroundStyles();

  function handleSearch() {
    onSearch({ keyword, start: 1 });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setKeyword(event.target.value);
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    var code = event.keyCode || event.which;
    if (code === KEYBOARD.Enter) {
      handleSearch();
    }
  }

  return (
    <>
      <Grid
        className={styles.search}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Grid container alignItems="flex-end">
            <Typography
              variant={isMobile ? "caption" : "button"}
              display="block"
              component="div"
              color="textPrimary"
              className={styles.search_total}
            >
              {rowCount}
            </Typography>
            <Typography
              variant={isMobile ? "caption" : "body1"}
              display="block"
              component="div"
              color="textSecondary"
            >
              {t("total")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={`${styles.search__filter} ${classOwns.bg}`}>
            <InputBase
              value={keyword}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              className={styles.search__filter_input}
              placeholder={t("search")}
              inputProps={{ "aria-label": "input search", maxLength: 50 }}
            />
            <Tooltip
              title={t(isShowAdvanced ? "basic_search" : "search")}
              placement="top"
            >
              <IconButton
                size={isMobile ? "small" : "medium"}
                onClick={handleSearch}
                aria-label="search"
              >
                <SearchIcon color="action" />
              </IconButton>
            </Tooltip>
            {isShowAdvanced && (
              <>
                <Divider
                  orientation="vertical"
                  className={styles.search__filter_divider}
                />
                <Tooltip title={t("advanced_search")} placement="top">
                  <IconButton
                    onClick={showAdvanceSearch}
                    color="secondary"
                    aria-label="filter"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchGroup;

import React from "react";
import { Route, Redirect } from "react-router-dom";

import { routeDatas } from "./private.components.route";

import { ROUTES } from "constants/navigation";
import { useAuthDataContext } from "context/auth.context";

const withAuth = (Component: any) => (props: any) => {
  const { isAuth } = useAuthDataContext();

  if (!isAuth) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.Login,
          state: { from: props.location }
        }}
      />
    );
  }
  return <Component {...props} />;
};

const PrivateRoute: React.SFC = () => (
  <>
    {routeDatas.map(({ component, ...rest }: any, i) => (
      <Route key={`key_r_p_${i}`} {...rest} component={withAuth(component)} />
    ))}
  </>
);

export default PrivateRoute;

import React from "react";
import style from "./layout.module.scss";
import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton
} from "components/ui-libraries";
import { SubjectIcon } from "../../ui-libraries/icons";

import NotificationsComponent from "../notifications/notifications.component";
import UserSettingsComponent from "../user-settings/user-settings.component";

import { useTranslation } from "react-i18next";

import { ROUTES } from "constants/navigation";

import classnames from "classnames";

interface IProps {
  isMobile: boolean;
  isOpen: boolean;
  isAuth: boolean;
  authData?: any;
  onLogout: Function;
  onToggle: any;
}

const LayoutHeader: React.FC<IProps> = ({
  isMobile,
  isAuth,
  isOpen,
  authData,
  onLogout,
  onToggle
}) => {
  const { t } = useTranslation(["common"]);

  if (!isAuth) {
    return null;
  }

  return (
    <AppBar
      id="a-h-t"
      color="default"
      classes={{
        root: classnames(style.header, {
          [style["header--close"]]: !isOpen,
          [style["header--mobile"]]: isMobile
        })
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters={true}
        classes={{ root: style.toolbar }}
      >
        <IconButton id="ico-app-menu" onClick={onToggle}>
          <SubjectIcon />
        </IconButton>
        <Link className={style.header__title} to={ROUTES.Dashboard}>
          <Typography variant="h6" color="textPrimary">
            {t("app_name")}
          </Typography>
        </Link>
        <UserSettingsComponent
          authData={authData}
          classes={{ avatar: style.header__settings }}
          onLogout={onLogout}
        />
      </Toolbar>
    </AppBar>
  );
};

export default LayoutHeader;

import React from "react";
import styles from "./table.module.scss";

import {
  AutoSizer,
  List,
  Size,
  CellMeasurerCache,
  InfiniteLoader,
  InfiniteLoaderChildProps,
} from "react-virtualized";

import { Typography } from "components/ui-libraries";

import InputSearch from "./table-search.component";
import Loading from "./table-ajax.component";
import Empty from "./table-empty.component";

import useTableHook from "./table.hook";
import { useThemesContext } from "context/theme.context";

const _cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 48,
});

const TableOwn: React.FC<any> = ({
  title,
  isLoading,
  columns,
  rowCount,
  datas,
  onRowClick,
  showAdvanceSearch,
  isShowAdvanced,
  indexSelected,
  onSearch,
  onLoadMore,
  order,
  orderBy,
  ...tableProps
}) => {
  const { isMobile } = useThemesContext();
  const { _headerRenderer, _rowRenderer, _isRowLoaded } = useTableHook({
    columns,
    datas,
    onRowClick,
    _cache,
    indexSelected,
    order,
    orderBy,
    onSearch,
  });

  return (
    <div className={styles.root}>
      {isLoading && <Loading />}

      {title && (
        <Typography
          variant={isMobile ? "subtitle2" : "h6"}
          color="textSecondary"
        >
          {title}
        </Typography>
      )}

      <InputSearch
        isMobile={isMobile}
        onSearch={onSearch}
        isShowAdvanced={isShowAdvanced}
        rowCount={rowCount}
        showAdvanceSearch={showAdvanceSearch}
      />

      {_headerRenderer(isMobile)}

      <div className={styles.root__table}>
        <InfiniteLoader
          isRowLoaded={_isRowLoaded}
          loadMoreRows={onLoadMore}
          rowCount={rowCount}
        >
          {({ onRowsRendered, registerChild }: InfiniteLoaderChildProps) => (
            <AutoSizer>
              {({ width, height }: Size) => {
                return (
                  <>
                    {rowCount === 0 ? (
                      <Empty style={{ width }} />
                    ) : (
                      <List
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        deferredMeasurementCache={_cache}
                        height={height}
                        width={width}
                        rowCount={datas.length}
                        rowHeight={48}
                        rowRenderer={_rowRenderer}
                        {...tableProps}
                      />
                    )}
                  </>
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    </div>
  );
};

export default TableOwn;

import React, { useState } from "react";

import { List, ListItem, ListItemText, ListItemIcon } from "../../ui-libraries";
import { PersonIcon, GroupIcon, LinkIcon } from "../../ui-libraries/icons";

import { ROUTES } from "constants/navigation";

import { useTranslation } from "react-i18next";

import { TIMEOUT } from "constants/common";

interface IUserSettingsMainProps {
  handleClose: any;
  history: any;
  authData: any;
}

let timeoutId: any = 0;

const UserSettingsMain: React.FC<IUserSettingsMainProps> = ({
  history,
  handleClose,
  authData
}) => {
  const [isProgress, setProgress] = useState(false);
  const { t } = useTranslation(["common"]);

  function goProjectMembers() {
    handleClose();
    history.push(ROUTES.ProjectMembers);
  }

  function goProfile() {
    handleClose();
    history.push(ROUTES.Profile);
  }

  function copySignupLink() {
    navigator.clipboard.writeText(decodeURIComponent(authData.signup_link));
    setProgress(true);

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setProgress(false);
    }, TIMEOUT.Default * 2);
  }

  return (
    <List>
      <ListItem button onClick={goProjectMembers}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText>{t("project_members")}</ListItemText>
      </ListItem>
      <ListItem button onClick={goProfile}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText>{t("profile")}</ListItemText>
      </ListItem>
      {authData.signup_link ? <ListItem button onClick={copySignupLink}>
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText>{isProgress ? t("copied_signup_link") : t("copy_signup_link")}</ListItemText>
      </ListItem> : "" }
    </List>
  );
};

export default UserSettingsMain;

import { lazy } from "react";
import { RouteProps } from "react-router-dom";

import { ROUTES } from "constants/navigation";

const routeDatas: RouteProps[] = [
  {
    exact: true,
    path: ROUTES.Home,
    component: lazy(() => import("pages/private/home/home.container"))
  },
  {
    path: ROUTES.Dashboard,
    component: lazy(() => import("pages/private/dashboard/dashboard.container"))
  },
  {
    exact: true,
    path: ROUTES.Profile,
    component: lazy(() => import("pages/private/profile/profile.container"))
  },

  {
    path: ROUTES.Messages,
    component: lazy(() => import("pages/private/messages/messages.container"))
  },
  {
    path: ROUTES.Notifications,
    component: lazy(() =>
      import("pages/private/notifications/notifications.container")
    )
  },
  {
    path: ROUTES.Feedback,
    component: lazy(() => import("pages/private/feedback/feedback.container"))
  },
  {
    path: ROUTES.ProjectMembers,
    component: lazy(() =>
      import("pages/private/project-members/project-members.container")
    )
  }
];

export { routeDatas };
